export default {
  app: {
    name: "OrderPoint Console",
    brand: "orderpoint",
    brandName: "OrderPoint"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://3ja3bytwvj.execute-api.us-east-1.amazonaws.com/beta"
  },
  proxyAPI: {
    NAME: "ProxyAPI",
    PATH: "/apiproxy",
  },
  graphql: {
    NAME: "graphql",
    PATH: "/graphql",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_e27i0HVLS",
    APP_CLIENT_ID: "5b427m3tro4lbkgur6emg8rq0s",
    IDENTITY_POOL_ID: "us-east-1:b8d018cd-8118-41e4-ba5b-876a8e257f60"
  }
};